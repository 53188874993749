import React, { useState } from 'react';
import Modal from '../Modal';
import UploadLicenseModal from './UploadLicenseModal';
import { Cureus } from '../../Cureus';

const LicenseFile = ({licenseType, setCollectionToUpdate, updateBannerLicensePath, licenseFile}) => {

  const [isLoading, setIsLoading] = useState(false);

  const openUploadLicenseModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <UploadLicenseModal handleFileUpload={handleFileUpload}/>
    });
  }

  const handleFileUpload = (fileData) => {
    let formData = new FormData()
    formData.append("file", fileData)

    setIsLoading(true)

    fetch(updateBannerLicensePath, {
      method: 'PUT',
      body: formData,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    },
    })
    .then((res) => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return Promise.reject(res.json())
      }
    })
    .then((json) => {
      setCollectionToUpdate(json);
      Cureus.Flash.success('Successfully updated banner image license.')
      window.scrollTo(0, 0)
      Modal.close()
    })
  }

  const renderFileUpload = () => {
    if (licenseType == 'banner_license_file') {
        if (licenseFile) {
          return (
            <div>
              <p>{licenseFile}</p>
              <a class='button small stencil-blue' href='#' onClick={openUploadLicenseModal}>Replace File</a>
            </div>
          )
        } else {
          return (
            <div>
              <a class='button small stencil-blue' href='#' onClick={openUploadLicenseModal}>Upload License File</a>
            </div>
        )}
    }
  }

  return (
    <div className='license-file'>
      {isLoading &&
        <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>}
      {renderFileUpload()}
    </div>
  )
}

export default LicenseFile;