import {Cureus} from '../Cureus';
Cureus.HubspotEventClickTrack = function(htmlSelector, eventName, hubspotPropertiesJSON='json') {

  let hubspotProperties;
  let isValidJSON = true;

  try {
    hubspotProperties = JSON.parse(hubspotPropertiesJSON);
  } catch (e) {
    isValidJSON = false;
  }

  if (!isValidJSON) { hubspotProperties = {};}

  document.querySelector(htmlSelector).addEventListener('click', function() {
    _hsq.push([
      'trackCustomBehavioralEvent',
      {
        name: eventName,
        properties: hubspotProperties,
      },
    ]);
  });
}