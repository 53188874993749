import React, {useState, useEffect} from 'react';
import EditFile from './EditFile';
import NewFile from './NewFile';
import License from './License';
import Consent from './Consent';
import PublishingStepButtons from '../Shared/PublishingStepButtons';
import { Cureus } from '../../Cureus';

const Main = ({collection, updateBannerImagePath, nextPath, prevPath, updateBannerLicensePath}) => {

  const [collectionToUpdate, setCollectionToUpdate] = useState(collection);
  const [licenseLink, setLicenseLink] = useState(collection.banner_license_link || null);
  const [licenseFile, setLicenseFile] = useState(collection.banner_license_file.url || null);
  const [consent, setConsent] = useState(collection.banner_patient_consent || null);
  const [disabled, setDisabled] = useState(null);
  const [licenseType, setLicenseType] = useState(licenseFile ? 'banner_license_file' : licenseLink ? 'banner_license_link' : null);

  useEffect(() => {
    resetDefaults();
    disableNextButton();
  },[])

  useEffect(() => {
    disableNextButton();
  },[licenseFile, licenseLink])

  useEffect(() => {
    resetDefaults();
    disableNextButton();
    setLicense();
    checkLicenseType();
  }, [collectionToUpdate]);

  const setLicense = () => {
    setLicenseFile(collectionToUpdate.banner_license_file_name)
    setLicenseLink(collectionToUpdate.banner_license_link || '')
  }

  const checkLicenseType = () => {
    setLicenseType(collectionToUpdate.banner_license_file_name ? 'banner_license_file' : collectionToUpdate.banner_license_link ? 'banner_license_link' : null)
  }

  const resetDefaults = () => {
    if(!collectionToUpdate.banner_patient_consent) {
      setConsent(false)
    }
  }

  const handleUpdateAll = (attributes, callback) => {
    $.ajax({
      url: updateBannerImagePath,
      type: 'PUT',
      dataType: 'json',
      data: attributes,
      success: function(res) {
        setCollectionToUpdate(res);
        Cureus.Flash.success('Successfully updated banner image settings.')
        window.scrollTo(0, 0)
        if (callback) {
          callback()
        }
      },
      error: function(res) {
        Cureus.Flash.alert(`${res.responseJSON.errors}`)
        window.scrollTo(0, 0)
      }
    })
  }

  const disableNextButton = () => {
    if (hasValidLicense() && collectionToUpdate.banner_image.url) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const hasValidLicense = () => {
    if ((licenseLink?.trim() != '') || (collectionToUpdate.banner_license_file.url)) {
      return true
    }
    return false
  }

  const formatUpdateParams = () => {
    return {
      banner_license_type: licenseType,
      banner_license_link: licenseLink, 
      banner_patient_consent: consent, 
      banner_license_file: licenseFile, 
      banner_image: collectionToUpdate.banner_image
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateAll(formatUpdateParams(), () => {window.location = nextPath});
  }

  const renderLicense = () => {
    if (collectionToUpdate.banner_image.url) {
      return (
        <div>
            <License collection={collectionToUpdate} updateBannerImagePath={updateBannerImagePath} setCollectionToUpdate={setCollectionToUpdate} licenseLink={licenseLink} setLicenseLink={setLicenseLink} licenseFile={licenseFile} setLicenseFile={setLicenseFile} setLicenseType={setLicenseType} licenseType={licenseType} updateBannerLicensePath={updateBannerLicensePath}/>
            <Consent collection={collectionToUpdate} consent={consent} setConsent={setConsent}/>
          </div>
      )
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          { collectionToUpdate && collectionToUpdate.preview_url ? (
            <div>
              <EditFile
              collection={collectionToUpdate }
              onUpdate={ setCollectionToUpdate }
              updateObjectFilePath={updateBannerImagePath }
              fileName={'banner_image_file'}
              transloaditTemplate={collectionToUpdate.transloadit_template}
              />
            </div>
          ) : ( 
          <div>
            <NewFile
              collection={collectionToUpdate }
              onUpdate={ setCollectionToUpdate }
              updateObjectFilePath={updateBannerImagePath }
              fileName={'banner_image_file'}
              transloaditTemplate={collectionToUpdate.transloadit_template}
            />
          </div>
          )}
          {renderLicense()}
        </div>
        <PublishingStepButtons prevPath={prevPath} saveFunction={() => {handleUpdateAll(formatUpdateParams())}} disabled={disabled}/>
      </form>
    </div>
  )
}

export default Main;