import React, {useState, useEffect} from "react";
import SearchBar from '../Shared/SearchBar/SearchBar';
import SmallCard from "./SmallCard";
import BigCard from "./BigCard";
import Filter from "../Shared/FilterTab/Filter";
import Pill from "../Shared/FilterTab/Pill";
import CollectionFilters from './Filters'

const Main = ({categories, submitPath, currentUser, dateRanges}) => {
  const [collections, setCollections] = useState([]);
  const [filterCategories, setFilterCategories] = useState(categories);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [type, setType] = useState([]);
  const [pills, setPills] = useState([]);

  // Filter states - Collection Type, Newest, Most Viewed, Most Articles
  const [activeOrder, setActiveOrder] = useState('newest');
  const [activeFilter, setActiveFilter] = useState('');
  const [order, setOrder] = useState({ attr: "published_at", dir: "desc", text: "Newest" })
  const [collectionType, setCollectionType] = useState('')
  const [dateData, setDateData] = useState({
    fromDate: dateRanges?.from || "",
    toDate: dateRanges?.to || "",
    dateRanges: dateRanges?.text || "",
    isActive: false,
  });

  const url = "/collections";

  useEffect(() => {
    transformCategoriesForFilter();
  }, [])

  useEffect(() => {
    fetchCollections();
  }, [type, collectionType, dateData, order])

  const transformCategoriesForFilter = () => {
    const transformed = categories.map((category, index)=>{
      return {...category,
      id: index + 1}
    })
    setFilterCategories(transformed);
  }

  const removePill = (pill) => {
    const updatedIds = pills.filter((item) => item.id !== pill.id);
    const updatedTypes = pills.filter((item) => item.id != pill.id).map((pill) => pill.name)
    setType(updatedTypes)
    setPills(updatedIds);
  }

  const buildParams = () => {
    let params = {
      type,
      collectionType,
      order
    };

    if (dateData.isActive) {
      const newDateParams = {
        from_date: dateData.fromDate,
        to_date: dateData.toDate,
        date_picker_active: dateData.isActive,
      };
      params = { ...params, ...newDateParams };
    }
    return params;
  };

  const fetchCollections = (optionalParams =  {}) => {
    const params = buildParams();
    $.ajax({
      type: 'GET',
      url: '/collections',
      dataType: 'json',
      data: { ...params, ...optionalParams },
      success: function(res) {
        setCollections(res.cards);
        $(document).foundation('reflow');
      }
    });
  }

  const formatToolTip = (collection) => {
    return collection.categories.map(cat => cat.name).join(', ');
  }

  const renderCard = (collection, index) => {
    const tooltip = formatToolTip(collection);

    if (collection.card_size == "big") {
      return (
        <BigCard collection={collection}  tooltip={tooltip} key={collection.id} index={index}/>
      )
    } else {
      return (
        <SmallCard collection={collection}  tooltip={tooltip} key={collection.id} index={index}/>
      )
    }
  }

  const onSubmit = () => {
    fireAmplitude('submitCollectionIdea', {})
    window.location.href = submitPath
  }

  const fireAmplitude = (eventName, eventParams) => {
    eventParams['event_type'] = eventName
    eventParams['user_id'] = currentUser ? currentUser.id : null
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: eventParams
    })
  }

  const setDefaultOrder = () => {
    setOrder({ attr: "published_at", dir: "desc", text: "Newest" });
    setActiveOrder('newest');
  }

  const updateFilter = (filterType, collectionFilter = '') => {
    switch (filterType) {
      case 'newest':
        if (activeOrder === 'newest' && order.dir === 'desc') {
          setOrder({ attr: "published_at", dir: "asc", text: "Newest" });
          setActiveOrder('newest');
        } else {
          setDefaultOrder();
        }
        break;
      case 'collectionType':
        if (collectionType === collectionFilter) {
          setCollectionType('');
          setActiveFilter('');
        } else {
          setCollectionType(collectionFilter);
          setActiveFilter(collectionFilter);
        }
        break;
      case 'mostViewed':
        if (activeOrder === 'mostViewed') {
          if (order.dir === 'desc') {
            setOrder({ attr: "total_views", dir: "asc", text: "Most Viewed" });
          } else {
            setDefaultOrder();
          }
        } else {
          setOrder({ attr: "total_views", dir: "desc", text: "Most Viewed" });
          setActiveOrder(filterType);
        }
        break;
      case 'mostArticles':
        if (activeOrder === 'mostArticles') {
          if (order.dir === 'desc') {
            setOrder({ attr: "article_count", dir: "asc", text: "Most Viewed" });
          } else {
            setDefaultOrder();
          }
        } else {
          setOrder({ attr: "article_count", dir: "desc", text: "Most Viewed" });
          setActiveOrder(filterType);
        }
        break;
      default:
        return '';
    }
  };

  const handleDateRangeSubmit = (fromDate, toDate, selectedRange, isActive) => {
    setDateData({
      fromDate,
      toDate,
      dateRanges: selectedRange,
      isActive,
    });
  };

  return (
    <div className='row all-collections-container'>
      <div className="top-section-container large-11 medium-11 small-12 columns small-centered">
        <h4 className='all-collections-heading'>CUREUS COLLECTIONS</h4>
        <h1 className='all-collections-title'>Looking for special issues? Look no further.</h1>
        <a className="button round submit-btn" onClick={onSubmit}>Submit an idea for your Collection &nbsp; <i class="far fa-edit"/></a>
      </div>
      <div className="row small-collapse">
        <div className='small-12 columns'>
          <SearchBar
            url={url}
            placeHolder = {'Search for a topic, specialty or specific collection here.'}
            isFilterMenuOpen={false}
            setCards={setCollections}
            setIsFilterMenuOpen={setIsFilterMenuOpen}
            page={"collections"}
            isFilterRequired={true}
            additionalParams={buildParams()}
          />
        </div>
        {pills.length > 0 &&
          <div className='small-12 columns'>
            <div className='pill-container'>
              {pills.map((pill) => {
                return <Pill
                  pill={pill}
                  removePill={removePill}/>
              })}
            </div>
          </div>
        }
        <div className='small-12 columns'>
          <CollectionFilters
            order={order}
            activeOrder={activeOrder}
            activeFilter={activeFilter}
            updateFilter={updateFilter}
            collectionType={collectionType}
            dateData={dateData}
            handleDateRangeSubmit={handleDateRangeSubmit}
          />
        </div>
      </div>
      <div className='row small-collapse'>
        <div className='small-12 columns'>
          <div className='all-collections-grid'>
          {collections.map((collection, index)=> {
              return renderCard(collection, index);
            })}
          </div>
        </div>
      </div>
      <Filter
        isMenuOpen={isFilterMenuOpen}
        setFilterMenuOpen={setIsFilterMenuOpen}
        contents={filterCategories}
        setCards={setCollections}
        page={"collections"}
        url={url}
        setPills={setPills}
        pills={pills}
        additionalParams={buildParams()}
        setTypeCallBack={setType}
      />
    </div>
  )
}

export default Main