import React from 'react';
import Modal from '../../Modal';

const CorrespondentModal = ({handleCorrespondent}) => {

  const handleSubmit = () => {
    handleCorrespondent();
    Modal.close();
  }

  return (
    <div id={"correspondent-reveal-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <h4>Please confirm corresponding author change.</h4>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="button secondary cancel-modal" role="button">Cancel</a>
            <button className="button" onClick={handleSubmit}>Confirm</button>
          </div>
        </div>
      </div>
  </div>
  )
}

export default CorrespondentModal;