import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const SingleBlindedPeerReview = ({setFooterText,setIncludeDefault}) => {
  useEffect(() => {
    setFooterText('')
    setIncludeDefault(true)
   }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>Peer Review Process | Single-Blind Peer Review | Cureus</title>
        <meta name='description' content="Explore Cureus' peer review process. Learn how our expert reviewers assess research through single-blind peer reviews. View our author guide to learn more." />
      </Helmet>
      <h1>Peer Review</h1>
      <h2>Single-Blind, Pre-Publication Peer Review</h2>
      <p><a href='/' target='_blank'>Cureus</a> is a formally peer-reviewed medical journal. All articles accepted for peer review undergo a single-blind, pre-publication peer review process in which reviewers know the identity of the authors, but authors do not know the identity of the reviewers.</p>
      <p>Reviewers are made anonymous through Cureus' automated system. Once a reviewer accepts the review invitation, they are assigned a Greek letter to be used for identification purposes by the authors (e.g. Reviewer Alpha).</p>
      <p>Any supplemental material included with the article is also subject to peer review. Reviews are not posted with the published articles. Medical students are ineligible to serve as peer reviewers.</p>


      <h2>Requirements to Satisfy Peer Review</h2>
      <p>Two completed reviews from journal-invited reviewers are required to satisfy peer review. As part of the Cureus peer review process, six peer reviewers are automatically invited from the Cureus internal peer review panel, consisting of <a href='/associate_editors' target='_blank'>Cureus Editorial Board members</a> and relevant specialists who have either volunteered for the panel or previously published as an author in Cureus.</p>
      <p>Reviewers are selected based on specialty, reviewer keywords, and activity. Once the article has entered peer review, three additional reviewers are automatically invited by Cureus every two days to ensure that the article completes the peer review process in a timely fashion.</p>
      <p>When combined with the author-suggested peer reviewers (see below), this ensures that a minimum of 11 peer reviewers are invited to review each article.</p>

      <h2>Author-Suggested Peer Reviewers</h2>
      <p>In addition to the independently-selected peer reviewers described above, authors are required to suggest a minimum of five reviewers when <a href='/author_guide#!/author-instructions/submitting-an-article' target='_blank'>submitting their draft</a>. While these reviews do not count towards the required minimum of two completed single-blind peer reviews, they do provide additional feedback to the authors.</p>
      <p>Authors should suggest unbiased and expert reviewers who will provide critical feedback. These reviewers are vetted by Cureus editorial staff as part of the peer review process. </p>
      <p>Positive reviews lacking substantial constructive feedback will be closely scrutinized by our editors and may be rejected. Think of it this way — your peer reviewers will be reviewed. Therefore, inviting credible reviewers is of paramount importance.</p>

      <h2>SIQ: Optional Post-Publication Review</h2>
      <p>Scholarly Impact Quotient™, or <a href='https://www.cureus.com/siq' target='_blank'>SIQ</a>, is an optional supplement to our pre-publication <a href='/reviewer_guide#!/how-it-works/introduction' target='_blank'>peer review process</a>. SIQ is not part of the formal Cureus publication process and is entirely optional, but it provides authors with immediate, valuable reader feedback.</p>
    </React.Fragment>
  )
}

export default SingleBlindedPeerReview