import React, { useState } from 'react';

const UploadTermsModal = ({handleFileUpload}) => {
  const [fileData, setFileData] = useState({})

  return (
    <div className="reveal-modal small banner-license-file-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Upload a File</h5>
          </div>
          <hr/>

          <div className="modal-content">
            <div className='modal-form'>
              <p>Important! Only PDF, JPEG, JPG, PNG, DOC, DOCX files are accepted.</p>
              <input type="file" onChange={(e) => {setFileData(e.target.files[0])}  }/>

              <div className="form-actions text-center">
                <a className='cancel-modal button special' style={{marginRight: '22px'}}>CANCEL</a>
                <a className='cancel-modal button primary' onClick={() => {handleFileUpload(fileData)}}>Add</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadTermsModal
