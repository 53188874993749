import { is } from 'date-fns/locale';
import React, {useState, useEffect, useRef} from 'react';

const SearchBar = ({url, placeHolder, setCards, isFilterMenuOpen, setIsFilterMenuOpen, page, isFilterRequired, setIsSearchResults, isPaginationUsed=false, isSpinnerUsed=false,setSpinning, additionalParams = {}}) => {

  // const [contents, setContents] = useState([]);
  const [type, setType] = useState('');
  const [term, setTerm] = useState('');
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      if (isSpinnerUsed) {
        setSpinning(true);
      }
      fetchContents();
    }
  }, [type, term]);

  const fetchContents = () => {
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      data: {
        ...additionalParams,
        type: type,
        searchTerm: term,
      },
      success: function(res) {
        if(isPaginationUsed){
          setIsSearchResults(true);
        }
        if(term == "" && isPaginationUsed) {
          setIsSearchResults(false);
        }
        setCards(res.cards);
        if (isSpinnerUsed) {
          setSpinning(false);
        }
      }
    });
  }

  const handleChange = (event) => {
    const debounced = _.debounce(function() {
      setType('')
      setTerm(event.target.value)
    }, 1000);
    debounced();
  }

  const openFilterMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  }

  const renderFilterButton = () => {
    return (
      <a className="filter-button-container" onClick={openFilterMenu}>
        <img className="filter-btn-icon" src="https://public.cureus.com/new-nav-bar/filter-icon.png"/>
        <p className="filter-text">Filter</p>
      </a>
    )
  }

  return (
    <div className="small-collapse">
      <div className={'small-12 columns pages-searchbar-container ' + (page === "channels" ? "channel-margin-top" : "")}>
        <img className="search-icon" src="https://public.cureus.com/new-nav-bar/search-icon.png"/>
        <input className='all-searchbar' placeholder={placeHolder} onChange={(e)=> {handleChange(e)}}/>
        {isFilterRequired && renderFilterButton()}
      </div>
    </div>
  )
}

export default SearchBar;