import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const StepByStepOverview = ({setFooterText}) => {

  useEffect(() => {
    setFooterText(<p>Sign up for Cureus today and submit your important medical research for publication.</p>);
   }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>Publishing Medical Research Papers | Our Process | Cureus </title>
        <meta name='description' content='Publishing medical research requires layers of scrutiny and editorial oversight. Learn more about the steps to publish a research paper through Cureus.' />
      </Helmet>
      <h1>Publication Process Overview</h1>
      <p>The process for publishing a medical research paper in Cureus may vary in length due to article complexity, article length and the availability of reviewers with sufficient domain knowledge. <strong>Cureus strives to publish articles within 4 weeks of submission.</strong></p>
      <p>All articles must be submitted through our online system. Emailed submissions will be discarded without editorial review. If you encounter technical issues during the submission process, please email <a href='mailto:support@cureus.com'>support@cureus.com.</a>Before submitting your own article, please read through the seven steps to publish a research paper in Cureus below.</p>
      <div role='list' className='big-numbers'>
        <div className='step-1'>
          <p>The submitting author enters all <a href='/author_guide#!/author-instructions/submitting-an-article'>article information</a>, including all co-authors with corresponding affiliations, and adds the article text along with any relevant media. The author is then required to disclose any relevant conflicts of interest and adhere to any relevant ethics statements. Lastly, the author enters contact information for at least five <a href='/reviewer_guide#!/overview/why-review-with-cureus'>potential reviewers</a>. For a more detailed look at the research paper submission and publishing process, please refer to our comprehensive <a href='#!/author-instructions'>Author Instructions</a>. The author submits the article for Editor Check, a preliminary review by the Cureus editorial team. Based on this review, the article is directed down one of the following paths:</p>
        </div>

        <div>
          <h2>Approved for Peer Review</h2>
          <p>If all Cureus editorial requirements have been met, the article will be approved for <a href='/author_guide#!/author-instructions/managing-peer-review'>peer review</a> with no editing fees required.</p>

          <h2>Preferred Editing Purchase Required</h2>
          <p>Review articles and submissions containing multiple formatting and/or language errors will require the purchase of our <a href='/cureus_editing_services'>Preferred Editing service</a> to proceed to peer review. During this step in the publishing process, the research paper cannot be edited while deferred for Preferred Editing. (<a href='/channels'>Channel</a> and <a href='/competitions'>competition</a> articles are not eligible for Preferred Editing.)</p>
          
          <h2>3rd Party Editing Service Required</h2>
          <p>If severe language issues are present, language editing from a third-party service will be required prior to resubmission.</p>

          <h2>Deferred for Revisions</h2>
          <p>The following issues will require correction by the author before our editorial review can proceed. If these errors are not fixed or other errors are found (<a href='/author_guide#!/author-instructions/references'>references</a>, subheaders, spelling, grammar, punctuation, etc.), the article may still require Preferred Editing before your medical research is considered for peer review and publishing.</p>
          <ul className='disc'>
            <li>Scientific/study-related revisions needed</li>
            <li>Too much text is duplicated from cited sources</li>
            <li>Tables that are incomprehensible, contain multiple values in a single cell or are submitted as figures</li>
            <li>Missing media items</li>
            <li>Videos that don’t play</li>
            <li>Visible identifying patient information</li>
            <li>Figures that are blurry, stretched, skewed, too dark or require cropping</li>
          </ul>
          
          <h2>Desk Rejected</h2>
          <p>The article is outside the scope of the journal, not applicable to the global healthcare community or is otherwise ill-suited for publication (e.g. faulty study design).</p>
          
          <h2>Blocked</h2>
          <p>The article is in direct violation of our submission policies (e.g. <a href='/reviewer_guide#!/plagiarism/suspect-plagiarism'>plagiarism</a>, academic fraud, attempts to circumvent peer or editorial review). Authors attempting to publish medical articles blocked for plagiarism or fraud, or otherwise circumventing the steps to publishing a research paper through our journal, will be permanently banned from Cureus.</p>
        </div>

        <div className='step-2'>
          <p>The article earns <strong>preliminary acceptance</strong> and is approved for peer review after the following has been confirmed:</p>
          <ul>
            <li className='check'>
              <strong>All Cureus editorial requirements have been addressed.</strong>
            </li>
            <li className='check'>
              <strong>Preferred Editing service has been purchased if required.</strong>
            </li>
            <li className='check'>
              <strong>Professional third-party language editing services have been utilized if required.</strong>
            </li>
          </ul>
          <p>Upon approval for peer review, invitations are emailed to the author-suggested reviewers as well as six reviewers automatically invited by Cureus. During peer review, the author can invite new reviewers and send reminders to previously invited reviewers via the author dashboard.</p>
        </div>

        <div className='step-3'>
          <p>The article is unlocked for editing when two Cureus-invited reviews have been submitted. Prior to submitting the research article for final editorial approval and publishing, the author may now choose to:</p>
          <ul className='letter'>
            <li>Wait for additional reviews (all article versions are saved and available to the author and reviewers) before revising, responding to each reviewer comment, and submitting for publication approval (Editor Approval) while also providing an explanation of the revisions.</li>
            <li>Make revisions and request that the article be re-reviewed by one or more reviewers who completed a review.</li>
            <li>Make revisions, respond to each reviewer comment, and submit for publication approval while also providing an explanation of the revisions.</li>
          </ul>
        </div>
        <p>Authors have the option to end the peer review period for all reviewers once their article has satisfied our peer review requirements. If chosen, this option will expire all outstanding and in-progress reviews, with the exception of any reviews that were started within the prior 48 hours. These reviewers are given a maximum of 24 hours to submit their review. However, the review period may end sooner if the author revises and submits for research publication approval.</p>
        <p>Authors who are waiting on a Cureus-invited review (and have received at least one review from an author-invited reviewer) can also choose to end the review period for the remaining author-invited reviewers. Any reviewers who started their review in the prior 48 hours will be notified that they have a maximum of 24 hours remaining to submit their review.</p>
        
        <div className='step-4'>
          <p>Once the article has been submitted for Editor Approval, an associate editor will conduct a final editorial review to confirm the article’s suitability for publication. This part of our research paper publishing process includes ensuring that all reviewer comments have been acknowledged and addressed appropriately.</p>
        </div>
        
        <div className='step-5'>
          <p>If the revised manuscript does not adequately reflect reviewer feedback, the associate editor is likely to defer publishing the research article. The author is then notified that further revision is required. If a completed peer review is determined to be illegitimate, the review will be rejected and the article returned to peer review (if the article no longer satisfies Cureus review requirements after the rejection).</p>
        </div>

        <div className='step-6'>
          <p>After the associate editor has completed their review, the article will enter the copy editing queue. Non-Preferred Editing articles may be deferred back to the submitting author for formatting and language revisions prior to the start of copy editing. The copy editor may also choose to email the submitting author directly with questions. The article is approved for publication when copy editing has been completed and the author is notified that they can now sign in and publish the research article from their dashboard.</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default StepByStepOverview;