import React, { useEffect} from 'react';
import { Helmet } from 'react-helmet';

const GettingStarted = ({setFooterText, setIncludeDefault}) => {

  useEffect(() => {
    setFooterText(
      <p>
        Join us in our mission to eliminate barriers to medical knowledge generation and dissemination by participating as a guest editor with key responsibilities in our cutting-edge online medical research journal.
      </p>
    );
    setIncludeDefault(true)
   }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>Guest Editing | Get Started as a Guest Editor | Cureus</title>
        <meta name="description" content="Become a guest editor at Cureus. Submit your collection proposal, take on guest editor responsibilities, and help shape medical research with your contribution." />
      </Helmet>
      <h1>Getting Started</h1>
      <p>
        The first step in becoming a Cureus guest editor is <a href='/collection_applications/new' target='_blank'>submitting your collection proposal.</a> Here’s what you should know before submitting your proposal.
      </p>

      <h2>Choosing a Topic</h2>
      <p>
        Cureus publishes peer-reviewed medical science, so it's important that your proposed topic sits firmly in this category. Please refer to <a href='/about' target='_blank'>our list of accepted medical specialties</a> for additional information. Additionally, extremely niche topics should be avoided, but it’s important that your topic is not too broad, as the articles in your collection should share a common and clear theme. If your collection contains too many articles, this common thread will be difficult to discern, negatively affecting the impact of your collection.
      </p>

      <h2>Guest Editor Qualifications</h2>
      <p>
        Qualified guest editors will possess a medical degree or similar equivalent degree. Medical students are not eligible as we prefer that you complete medical school before taking on this additional academic responsibility.
      </p> 
      <p>  
        However, medical residents can contribute as guest editors provided they include senior co-editors who have completed residency training. A maximum of five co-editors (in addition to the primary guest editor) are permitted per collection.
      </p>

      <h2>Submitting an Application</h2>
      <p>
        Those interested in guest editing a collection should complete and submit a collection proposal using the online form. Names and links to online profiles for all co-guest editors should be included in the ‘Collection description’ field. All fields are required. We strive to review all proposals within two weeks of submission.
      </p>
    </React.Fragment>
    )
  }

  export default GettingStarted;