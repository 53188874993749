import React, { useState, useEffect } from 'react';
import {
  HashRouter,
} from 'react-router-dom';
import Ad from '../Shared/Ad';
import TopNavMenu from './TopNavMenu';
import SearchBar from './SearchBar';
import SideNavMenu from './SideNavMenu';
import SearchResults from './SearchResults';
import AuthorGuide from './AuthorGuide';
import MediaGuide from './MediaGuide';
import ReviewerGuide from './ReviewerGuide';
import GuestEditorGuide from './GuestEditorGuide';
import AcademicChannelsGuide from './AcademicChannelGuide';
import Footer from './Footer';

const Main = ({ guide, mainTitle, title, bannerAd, squareAd, sections, nextPages, urls }) => {

  const [query, setQuery] = useState('');
  const [location, setLocation] = useState([]);
  const defaultFooterText = (<p>Join us in our mission to eliminate barriers to the generation and dissemination of medical knowledge with our cutting-edge online medical research journal.</p>)
  const [footerText, setFooterText] = useState(() => defaultFooterText);
  const [includeDefault, setIncludeDefault] = useState(false);

  useEffect(() => {
    $(document).foundation({
      accordion: {
        // specify the class used for accordion panels
        content_class: 'content',
        // specify the class used for active (or open) accordion panels
        active_class: 'active',
        // allow multiple accordion panels to be active at the same time
        multi_expand: true,
        // allow accordion panels to be closed by clicking on their headers
        // setting to false only closes accordion panels when another is opened
        toggleable: true
      }
    });
    if(typeof(bannerAd) !== 'object') {
      $('#banner-ad').append(bannerAd)
      $('#square-ad').append(squareAd)
    }
    window.addEventListener('hashchange', navigate, false);
    navigate();
  }, []);

  useEffect(() => {
    if (location[2]) {
      var position = location[2].split('##')[1];
      var ele = document.querySelectorAll(".page-body")[0].querySelectorAll("ul, p")[position]
      if (ele) {
        ele.addClass('focused');
        ele.scrollIntoView({behavior: 'smooth', block: 'center'})
      }
    }
  }, [location])
  
  function getLocation() {
    var normalizedHash = window.location.hash.replace(/^#!\/?|\/$/g, '');
    return normalizedHash.split('/')
  }

  function navigate() {
    setLocation(getLocation())
    window.scroll({top: 0, behavior: 'smooth'});
  }

  function renderBannerAd() {
    if(typeof(bannerAd) === 'object') {
      return (
        <div className='cureus-ad-wrapper page-banner-ad show-for-large-up' >
          <Ad
            props={ bannerAd }
            size={ "banner" }
          />
        </div>
      )
    } else {
      return (
        <div id="banner-ad"/>
      )
    }
  }

  function renderBreadcrumbs() {
    var left = mainTitle;
    var right = title;
    var href = '/guides'
    if (location[0] === 'search') {
      left = title;
      right = 'Search Results'
      href = '#!'
    }
    return (
      <div className='row nav-header'>
        <div className={`page-nav-label large-4 columns ${guide == 'academic_channels_guide' ? "channels" : ""}`}>
          <a href={ href }>{ left }</a>
          <i className="fas fa-chevron-right sm"/>
          <span className='blue'>{ right }</span>
        </div>
        <div className='large-8 columns' style={{padding: 0}}>
          <SearchBar classes={ "page" } doSearch={ doSearch }/>
        </div>
      </div>
    )
  }

  function doSearch(evt) {
    if (evt.key == 'Enter' || evt.type == 'click') {
      var value = $("#search-input")[0].value;
      window.location.hash = '#!/search';
      setQuery(value);
    }
  }

  function renderTopNav() {
    if (location[0] !== 'search') {
      return (
        <div className='row page-top-nav hide-for-large-up'>
          <TopNavMenu 
            sections={ sections }
            location={ location }
          />
        </div>
      )
    }
  }

  function renderSquareAd() {
    if(typeof(bannerAd) === 'object') {
      return (
        <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
          <Ad
            props={ squareAd }
            size={ "square" }
          />
        </div>
      )
    } else {
      return (
        <div id="square-ad"/>
      )
    }
  }

  function renderSideNav() {
    return (
      <div className='page-side-nav large-4 columns show-for-large-up'>
        { location[0] !== 'search' && <SideNavMenu 
          title={ title }
          sections={ sections }
          location={ location }
        /> }
        <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
        {renderSquareAd()}
        </div>
      </div>
    )
  }

  function renderNextPage() {
    var nextPage = nextPages[location[1]];
    if (nextPage) {
      return (
        <div className='page-next'>
          <a href={ `#!/${nextPage.section}/${nextPage.page}` }>
            <u>{ nextPage.title }</u>
            <i className="fas fa-chevron-right"/>
          </a>
        </div>
      )
    }
  }

  function renderPage() {
    switch(guide) {
      case 'author_guide':
        return <AuthorGuide query={ query } urls={ urls } setFooterText={setFooterText} setIncludeDefault={setIncludeDefault}/>
      case 'media_guide':
        return <MediaGuide query={ query } urls={ urls }/>
      case 'reviewer_guide':
        return <ReviewerGuide query={ query } urls={ urls } setFooterText={setFooterText}/>
      case 'academic_channels_guide':
        return <AcademicChannelsGuide query={ query } urls= { urls}/>
      case 'guest_editor_guide':
        return <GuestEditorGuide query={ query } urls= { urls} setFooterText={setFooterText} setIncludeDefault={setIncludeDefault}/>
      case 'search':
        return <SearchResults query={ query } guide={ guide }/>
    }
  }

  return (
    <HashRouter hashType={ "hashbang" }>
      <div className='cureus-page'>
        { renderBannerAd() }
        { renderTopNav() }
        <div className='content-container page'>
          { renderBreadcrumbs() }
          <div className='row page-contents'>
            { renderSideNav() }
            <div className='page-body large-8 columns'>
              { renderPage() }
            </div>
            { renderNextPage() }
          </div>
          <Footer text={footerText} includeDefault={includeDefault}/>
        </div>
      </div>
    </HashRouter>
  )
}

export default Main;