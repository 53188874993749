import React from 'react';

const Consent = ({consent, setConsent}) => {
  
  const handleCheckboxChange = (e) => {
    setConsent(e.target.checked);
  }

  return (
    <div className='consent'>
      <div className='title'>Patient consent (if applicable)</div>
      <div className='consent-checkbox'>
        <input 
          type='checkbox'
          checked={consent}
          onChange={(e) => handleCheckboxChange(e)}
          id="patient-consent-checkbox"
        />
        <label htmlFor="patient-consent-checkbox">I've obtained patient's consent</label>
      </div>
    </div>
  )
};

export default Consent;