import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import useClickOutside from '../useClickOutside';

const ArticleDateSelector = ({
  selectedRange,
  fromDate,
  toDate,
  handleDateRangeSubmit,
  datePickerActive,
}) => {
  const [open, setOpen] = useState(false);
  const [calendarRange, setCalendarRange] = useState(selectedRange);
  const [from, setFrom] = useState(fromDate);
  const [to, setTo] = useState(toDate);
  const [isActive, setIsActive] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [maxDate, setMaxDate] = useState();

  const dropDownMenuRef = useRef()
  useClickOutside(dropDownMenuRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      setMaxDate(toDate);
      return;
    }
    if (validDateInput()) {
      handleDateRangeSubmit(from, to, calendarRange, isActive);
    } else {
      window.Cureus.Flash.alert(
        "Invalid date range, please input a valid date range.",
      );
    }
  }, [calendarRange]);

  const renderShowAllButton = () => {
    return (
      <a
        className="show-all-btn"
        key={calendarRange}
        onClick={showAllRange}
        href="#"
      >
        Show all
      </a>
    );
  };

  const showAllRange = (event) => {
    event.preventDefault();
    setCalendarRange("default");
    setIsActive(false);
  };

  const updateFrom = (e) => {
    setFrom(e.target.value);
  };

  const updateTo = (e) => {
    setTo(e.target.value);
  };

  const applyButton = () => {
    setOpen(false);
    setCalendarRange(from + " - " + to);
    setIsActive(true);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const calendarIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="calendar-icon"
    >
      <path
        d="M7.90005 13.975C7.60005 13.975 7.34605 13.875 7.13805 13.675C6.92938 13.475 6.82505 13.2167 6.82505 12.9C6.82505 12.6 6.92938 12.3457 7.13805 12.137C7.34605 11.929 7.60005 11.825 7.90005 11.825C8.20005 11.825 8.45405 11.929 8.66205 12.137C8.87072 12.3457 8.97505 12.6 8.97505 12.9C8.97505 13.2167 8.87072 13.475 8.66205 13.675C8.45405 13.875 8.20005 13.975 7.90005 13.975ZM12 13.975C11.7 13.975 11.446 13.875 11.238 13.675C11.0294 13.475 10.925 13.2167 10.925 12.9C10.925 12.6 11.0294 12.3457 11.238 12.137C11.446 11.929 11.7 11.825 12 11.825C12.3 11.825 12.5544 11.929 12.763 12.137C12.971 12.3457 13.075 12.6 13.075 12.9C13.075 13.2167 12.971 13.475 12.763 13.675C12.5544 13.875 12.3 13.975 12 13.975ZM16.1 13.975C15.8 13.975 15.546 13.875 15.338 13.675C15.1294 13.475 15.025 13.2167 15.025 12.9C15.025 12.6 15.1294 12.3457 15.338 12.137C15.546 11.929 15.8 11.825 16.1 11.825C16.4 11.825 16.6544 11.929 16.863 12.137C17.071 12.3457 17.175 12.6 17.175 12.9C17.175 13.2167 17.071 13.475 16.863 13.675C16.6544 13.875 16.4 13.975 16.1 13.975ZM5.07505 22.2001C4.44172 22.2001 3.90438 21.9794 3.46305 21.538C3.02105 21.0961 2.80005 20.5584 2.80005 19.925V6.07505C2.80005 5.44172 3.02105 4.90405 3.46305 4.46205C3.90438 4.02072 4.44172 3.80005 5.07505 3.80005H6.00005V1.80005H8.15005V3.80005H15.85V1.80005H18V3.80005H18.925C19.5584 3.80005 20.0961 4.02072 20.538 4.46205C20.9794 4.90405 21.2001 5.44172 21.2001 6.07505V19.925C21.2001 20.5584 20.9794 21.0961 20.538 21.538C20.0961 21.9794 19.5584 22.2001 18.925 22.2001H5.07505ZM5.07505 19.925H18.925V10H5.07505V19.925ZM5.07505 8.00005H18.925V6.07505H5.07505V8.00005ZM5.07505 8.00005V6.07505V8.00005Z"
        fill="#1395B7"
      />
    </svg>
  );

  const filterArrowIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.41289 11.2341L11.0199 14.6179C11.563 15.1274 12.4404 15.1274 12.9835 14.6179L16.5905 11.2341C17.4679 10.411 16.8412 9 15.6017 9L8.38776 9C7.14829 9 6.53552 10.411 7.41289 11.2341Z"
        fill="#ABB5BE"
      />
    </svg>
  );

  const rangeSelector = () => {
    if (open) {
      return (
        <div
          className="f-dropdown fancy-date-selector-content open"
          aria-hidden="false"
        >
          <div className="date-inputs-wrap">
            <div className="date-input" style={{ width: "165px" }}>
              <label htmlFor="from-3">From</label>
              <input
                onChange={updateFrom}
                id="from-3"
                name="from"
                type="date"
                value={from}
                className="hasDatepicker"
                min="2009-01-01"
                max={toDate}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="date-input" style={{ width: "165px" }}>
              <label htmlFor="to-3">To</label>
              <input
                onChange={updateTo}
                id="to-3"
                name="to"
                type="date"
                value={to}
                className="hasDatepicker"
                min="2009-01-01"
                max={maxDate}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
          <div className="date-filters-wrap">
            <div className="date-filters">{renderShowAllButton()}</div>
            <input
              className="button secondary tiny apply-btn"
              type="button"
              value="Apply"
              onClick={applyButton}
            />
            <a className="cancel-btn" onClick={() => setOpen(false)}>
              Cancel
            </a>
          </div>
        </div>
      );
    }
  };

  const displaySelectedDays = () => {
    let selectedRange = calendarRange;
    if (selectedRange === "default") {
      return "All";
    } else {
      return convertToShortDateFormat(selectedRange);
    }
  };

  const convertToShortDateFormat = (selectedRange) => {
    try {
      let dateRangeArray = selectedRange.split(" - ");
      let dateFrom = new Date(`${dateRangeArray[0]}T00:00:00`);
      let dateTo = new Date(`${dateRangeArray[1]}T00:00:00`);

      const formattedDateFrom = format(new Date(dateFrom), "MMM d, yyyy");
      const formattedDateTo = format(new Date(dateTo), "MMM d, yyyy");
      return formattedDateFrom + " - " + formattedDateTo;
    } catch (error) {
      return null;
    }
  };

  const validDateInput = () => {
    return (
      convertToShortDateFormat(calendarRange) || calendarRange === "default"
    );
  };

  const handleActiveClass = () => {
    return datePickerActive ? "active" : "";
  };

  return (
    <div className="show-for-medium-up calendar-container" ref={dropDownMenuRef}>
      <div className="fancy-date-selector">
        <div
          role="button"
          onClick={toggleOpen}
          className="time-selector-container current"
        >
          <span className={handleActiveClass()}>{displaySelectedDays()}</span>
          {calendarIcon}
          {filterArrowIcon}
          {rangeSelector()}
        </div>
      </div>
    </div>
  );
};

export default ArticleDateSelector;
