import React, { forwardRef, memo } from 'react';
import ArticleDateSelector from '../../NewArticleIndex/ArticleDateSelector';
import Dropdown from '../../Shared/Dropdown';

const Filters = ({
  dateData,
  pageCountOverview,
  selectedSort,
  updateSortDirection,
  setPerPage,
  perPage,
  handleDateRangeSubmit,
}) => {
  const { dateRanges, toDate, fromDate, isActive } = dateData

  const filterArrowIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        d='M17.6569 16.2426L19.0711 14.8284L12.0001 7.75732L4.92896 14.8284L6.34317 16.2426L12.0001 10.5857L17.6569 16.2426Z'
        fill='#ABB5BE'/>
    </svg>
  );

  const handlePerPageChange = (value) => {
    setPerPage(value);
  };

  const RawButton = memo(({ forwardedRef, isLoading, disabled, ...props }) => {
    return (
      <button {...props} ref={forwardedRef}>
        {props.children}
      </button>
    );
  });

  const Button = forwardRef((props, ref) => (
    <RawButton {...props} forwardedRef={ref}/>
  ));

  const dropDownItems = [
    { content: 20, onItemClick: () => handlePerPageChange(20) },
    { content: 50, onItemClick: () => handlePerPageChange(50) },
    { content: 100, onItemClick: () => handlePerPageChange(100) },
  ];

  const { dir } = selectedSort;

  return (
    <div className="filters-container">
      <div className="date-filters-container">
        <button
          className={`filter-date-order ${dir === "desc" ? "desc" : "asc"}`}
          onClick={() => updateSortDirection(dir)}
        >
          Newest{filterArrowIcon}
        </button>
        <ArticleDateSelector
          fromDate={fromDate}
          toDate={toDate}
          handleDateRangeSubmit={handleDateRangeSubmit}
          selectedRange={dateRanges}
          datePickerActive={isActive}
        />
      </div>

      <div className="pagination-filters-container">
        <div className="pagination-results-count">{pageCountOverview}</div>
        <div className="per-page-filter-container">
          <Dropdown
            toggle={
              <Button>
                Show {perPage} {filterArrowIcon}
              </Button>
            }
            items={dropDownItems}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);