import React, {useState, useEffect} from 'react';

const Filter = ({
  isMenuOpen,
  setFilterMenuOpen,
  contents,
  setCards,
  page,
  url,
  setPills,
  pills,
  enableFetchContents=true,
  closeFilterAndSearchOnClearClick=false,
  additionalParams = {},
  setTypeCallBack,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [contentList, setContentList] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [type, setType] = useState([]);

  useEffect(() => {
    const navIconToggle = document.querySelector('#main-nav-avatar-icon');
    const navOverlayToggle = document.querySelector('#mobile-overlay');
    if (navIconToggle) {
      navIconToggle.addEventListener('click', handleIconNavToggleClick);
    }
    if (navOverlayToggle) {
      navOverlayToggle.addEventListener('click', handleIconNavToggleClick);
    }

    return () => {
      if (navIconToggle) {
        navIconToggle.removeEventListener('click', handleIconNavToggleClick);
      }
      if (navOverlayToggle) {
        navOverlayToggle.removeEventListener('click', handleIconNavToggleClick); // Added the removal for navOverlayToggle
      }
    };
  }, []);

  useEffect(() => {
    if (pills.length >= 0) {
        const selectedIds = pills.map((pill) => pill.id);
        setSelectedCategoryIds(selectedIds);
    }
  }, [pills])

  useEffect(() => {
    if (enableFetchContents) {
      fetchContents();
    }
  }, [type])

  useEffect(() => {
    setIsFilterMenuOpen(isMenuOpen)
    setContentList(contents);
  }, [isMenuOpen])

  const fetchContents = () => {
    if (setTypeCallBack) {
      setTypeCallBack(type);
    }

    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      data: {
        ...additionalParams,
        type: type,
      },
      success: function(res) {
        setCards(res.cards)
      }
    });
  }

  // prevent filter toggle on overlay click
  const handleIconNavToggleClick = () => {
    let filterTab = document.querySelector(".filter-section");
    filterTab.classList.toggle('hide');
  }

  const closeFilter = () => {
    setFilterMenuOpen(!isMenuOpen);
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  const handleCheckboxChange = (event, content) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedCategoryIds((prevSelected) => [...prevSelected, content.id]);
    } else {
      setSelectedCategoryIds((prevSelected) =>
        prevSelected.filter((id) => id !== content.id)
      );
    }
  };

  const getSelectedIdsAndNames = (contentList, selectedCategoryIds) => {
    const selectedCategories = contentList.filter(item => selectedCategoryIds.includes(item.id));
    const categoryIds = selectedCategories.map(category => category.category_id);
    const categoryNames = selectedCategories.map(category => category.name);

    return {
      categoryIds: categoryIds.length ? categoryIds : ['all'],
      categoryNames: categoryNames.length ? categoryNames : ['all'],
      selectedCategoryNamesToSend: selectedCategories
    };
  }

  const applyFilterAndRedirect = () => {
    if (page == "pastCompetitions") {
      const { categoryNames } = getSelectedIdsAndNames(contentList, selectedCategoryIds);
      const lowerCaseNames = categoryNames.map((name)=>name.toLowerCase());
      setType(lowerCaseNames);
      closeFilter();
    }

    else if (page == "collections") {
      const { categoryNames, selectedCategoryNamesToSend } = getSelectedIdsAndNames(contentList, selectedCategoryIds);
      setType(categoryNames);
      setPills(selectedCategoryNamesToSend);
      closeFilter();
    }

    else {
      const categories = formatSelectedCategories();
      const queryString = Object.entries(categories)
      .map(([categoryId, categoryName]) => `categories[${categoryId}]=${encodeURIComponent(categoryName)}`)
      .join('&');

    const redirectUrl = `/articles?${queryString}`;
    window.location.href = redirectUrl;
    }
  }

  const formatSelectedCategories = () => {
    const categories = selectedCategoryIds.reduce((acc, categoryId) => {
      const category = contentList.find((item) => item.id === categoryId);
      if (category) {
        acc[category.id] = category.name;
      }
      return acc;
    }, {});
    return categories;
  }


  const clearFilter = () => {
    setSelectedCategoryIds([]);
    if (closeFilterAndSearchOnClearClick) {
      setFilterMenuOpen(!isMenuOpen);
      applyFilterAndRedirect();
      setPills([]);
    }
  }

  return (
    <div className="filter-section">
      <div className={'overlay ' + (isFilterMenuOpen ? "open" : "close") } onClick={closeFilter} />
      <div className={"filter-menu " + (isFilterMenuOpen ? "open" : "")}>
        <div className="filter-header">
          <h6>Category</h6>
          <a onClick={closeFilter}>
            <img className="close-icon" src="https://public.cureus.com/new-nav-bar/blue-close-buttonpng.png"/>
          </a>
        </div>
        <div className="checkbox-section">
          {contentList.map((content) => {
            return (
              <div key={content.id} className={"checkbox-container " + (selectedCategoryIds.includes(content.id) ? "checked" : "")}>
                <input
                  id={content.id}
                  className="checkbox"
                  type="checkbox"
                  checked={selectedCategoryIds.includes(content.id)}
                  onChange={(e) => handleCheckboxChange(e, content)}
                />
                <label htmlFor={content.id}>{content.name}</label>
              </div>
            )
          })}
        </div>
        <div className="buttons-container">
          <a className="button large apply-btn font" onClick={applyFilterAndRedirect}>APPLY</a>
          <a className="text-center clear-section font" onClick={clearFilter}>CLEAR</a>
        </div>
      </div>
    </div>
  )
};

export default Filter;