import React, {useEffect, useState} from 'react';
import { Cureus } from '../../Cureus';

const ApprovalEditorRejectReviewModal = ({messagePath, greek}) => {
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    new Cureus.CKEditor('reject_message_text',  { 
      editor_type: 'default',
      ckeditor: {height: 300},
      change_listener: "#reject_message_text"
    })
  }, []);

  const handleSubmit = () => {
    setDisableSubmit(true);
    const text = $('#reject_message_text')[0].value;
    if (text) {
      $.ajax({
        type: 'POST',
        url: messagePath,
        data: {
          reject_review: true,
          message: text,
          reviewer: greek
        },
      }).done(() => {
        window.location.reload();
      });
    }
  };

  return (
    <div className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal' >
            <img src="https://public.cureus.com/close_x.png" />
          </a>
        </div>
        <div className="modal-content">
          <h4>Should this peer review be rejected?</h4>
          <p>Please explain why this peer review should be rejected. Your message will be posted on the article message board and emailed to the Managing Editor and Editorial Operations Supervisor for review. If the rejection is approved, your message will be sent to the reviewer and submitting author.</p>
          <p>Reviews should only be rejected if they lack substantive critical feedback i.e. low effort. Disagreeing with a peer reviewer is not grounds for peer review rejection. Rejected reviews do not count towards the required number of reviews.</p>
          <label htmlFor='reject_message_text'>Message text</label>
          <textarea id='reject_message_text'/>
          <div className='modal-footer'>
            <a href='#' className='button secondary cancel-modal'>Cancel</a>
            <a className='button alert' onClick={() => handleSubmit()} disabled={disableSubmit}>Post on Message Board</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApprovalEditorRejectReviewModal;