import React from 'react';
import LicenseFile from './LicenseFile';
import LicenseLink from './LicenseLink';

const License = ({collection, setCollectionToUpdate, licenseLink, setLicenseLink, licenseFile, setLicenseType, licenseType, updateBannerLicensePath}) => {

  const setTypeWrapper = (e) => {
    setLicenseType(e.target.value)
  }

  return (
    <div className='image-license'>
      <p className='title'>Image license:</p>
      <div className='upload-container'>
        <div className='upload-selections' style={{display : 'flex', gap: '2.5rem'}}>
          <label>
            <input 
              type="radio"
              id="radio-link"
              value="banner_license_link"
              checked={licenseType == 'banner_license_link'}
              onChange={(e) => setTypeWrapper(e)}
              label='Upload a link license'
              style={{marginRight: ".25rem"}}
            />
            Upload a link license
          </label>
          <label>
            <input
              type="radio"
              id="radio-device"
              value='banner_license_file'
              onChange={(e) => setTypeWrapper(e)}
              checked={licenseType == 'banner_license_file'}
              label='Upload from device'
              style={{marginRight: ".25rem"}}
            />
            Upload from device
          </label>
        </div>
        <LicenseLink licenseLink={licenseLink} setLicenseLink={setLicenseLink} licenseType={licenseType}/>
        <LicenseFile collection={collection} licenseType={licenseType} licenseFile = {licenseFile} setCollectionToUpdate={setCollectionToUpdate} updateBannerLicensePath={updateBannerLicensePath}/>
      </div>
    </div>
  )
};

export default License;