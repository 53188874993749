import React from 'react';

const LicenseLink = ({licenseType, licenseLink, setLicenseLink}) => {

  const renderLinkUpload = () => {
    if (licenseType == 'banner_license_link') {
      return (
        <div className='link-license'>
          <input 
            type='text'
            placeholder='Enter link with http(s)'
            value={licenseLink}
            onChange={(e) => {setLicenseLink(e.target.value)}}
          />
        </div>
      )
    }
  }

  return (
    <div className='license-link'>
      {renderLinkUpload()}
    </div>
  )
}

export default LicenseLink;