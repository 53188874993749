import React, { cloneElement, isValidElement, useRef, useState } from 'react';
import useClickOutside from '../useClickOutside';
import List from './List/List';

const Dropdown = ({ items, toggle, open = false }) => {
  const [isOpen, setIsOpen] = useState(open);

  const dropDownMenuRef = useRef()
  useClickOutside(dropDownMenuRef, () => {
    setIsOpen(false);
  });

  const toggleClone =
    isValidElement(toggle) && cloneElement(toggle, { onClick: () => setIsOpen(!isOpen)})

  return (
    <div ref={dropDownMenuRef} className="drop-down-container">
      {toggleClone}
      <List items={items} isOpen={isOpen} onSelect={() => setIsOpen(false)}/>
    </div>
  )
}

export default Dropdown;