
var createReactClass = require('create-react-class');
import React from 'react';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';
import AuthorForm from './AuthorForm';
import Modal from '../../Modal';
import ChangeEmailModal from './ChangeEmailModal';
import { Cureus } from '../../../Cureus';
import DeleteAuthorModal from './DeleteAuthorModal';
import CorrespondentModal from './CorrespondentModal';

const Row = createReactClass({
  getInitialState: function() {
    return {
      user: _.clone(this.props.usership.user),
      article_affiliations: this.cloneAffiliations(this.props.usership.article_affiliations),
      errors: this.cloneErrors(this.props.usership.affiliation_errors),
      nameChanged: false,
      affiliationChanged: false,
      spinner: false,
      channelManagementCced: this.props.usership.cced
    }
  },

  cloneAffiliations: function(affiliations) {
    if (affiliations) {
      return affiliations.map(function(aff) { return _.clone(aff) })
    }
  },

  cloneErrors: function(errors) {
    if (errors) {
      var newErrors = {}
      if (errors.affiliations) {
        newErrors.affiliations = errors.affiliations.map(function(err) { return _.clone(err) })
      }
      return newErrors
    }
  },

  componentDidMount: function() {
    if(this.props.isAuthorships) {
      Cureus.SplitButton.init();
      $(document).foundation('tooltip', 'reflow');
    }
  },

  handleEdit: function(e) {
    this.props.onEdit(this.props.usership.id, this.props.usership);
    e.preventDefault();
  },

  toggleSpinner: function() {
    var spinner = this.state.spinner
    this.setState({spinner: !spinner})
  },

  handleRemove: function(e) {
    e.preventDefault()
    if(this.props.isAuthorships) {
      Modal.open({
        children: <DeleteAuthorModal
          removeAuthorRequest={this.removeAuthorRequest}
          pastEditorCheck={this.props.pastEditorCheck}
          isCollection={this.props.isCollection}
        />
      });
    } else {  
      this.removeAuthorRequest()
    }
  },

  removeAuthorRequest: function() {
    var self = this;
    UserToolUtils.deleteDestroy({
      id: self.props.usership.id,
      params: self.props.defaultParams,
      endpointUrl: self.props.endpointUrl,
      success: function() {
        UserToolUtils.getIndex({
          endpointUrl: self.props.endpointUrl,
          params: self.props.defaultParams,
          success: self.props.updateUserships
        })
        if($('#delete-modal').length !== 0) {
          Modal.close();
        }
        $(self.refs.container).foundation("reveal", "close");
      },
      error: function() {
        alert("This author could not be deleted. Please try again.")
      }
    })
  },

  handleNameChange: function (evt) {
    var user = this.state.user
    var nameChanged = this.state.nameChanged || user[evt.target.name] != evt.target.value
    user[evt.target.name] = evt.target.value;

    let error_key = evt.target.dataset.error_key
    var errors = this.state.errors;
    if (errors && errors.name) {
      var name_error = errors.name[0]
      if (name_error) {
        name_error[error_key] = null
      }
    }

    this.setState({user: user, nameChanged: nameChanged});
  },

  handleAffiliationChange: function(evt) {
    let idx = parseInt(evt.target.dataset.idx)
    let error_key = evt.target.dataset.error_key
    var affiliations = this.state.article_affiliations
    var affiliation = affiliations[idx]
    var errors = this.state.errors;
    var newValue;

    if(evt.target.name == 'confirmInstitution' || evt.target.name == 'confirmDepartment') {
      newValue = evt.target.checked
    } else {
      newValue = evt.target.value
      if(evt.target.name == 'departmentName') {
        affiliation["confirmDepartment"] = false
      }
      if(evt.target.name == 'institutionName') { 
        affiliation["confirmInstitution"] = false
      }
      if (errors && errors.affiliations) {
        var aff_error = errors.affiliations[0][idx]
        if (aff_error) {
          aff_error[error_key] = null
        }
      }
    }
    var affiliationChanged = this.state.affiliationChanged || affiliation[evt.target.name] != newValue
    
    affiliation[evt.target.name] = newValue
    affiliations[idx] = affiliation
    this.setState({affiliations: affiliations, affiliationChanged: affiliationChanged, errors: errors})
  },

  handleCountryChange: function(country, idx) {
    // build dummy event object
    let evt = {
      target: {
        dataset: {
          idx: idx
        },
        name: "country",
        value: country
      }
    }
    this.handleAffiliationChange(evt)
  },

  handleAuthorshipSubmit: function() {
    var self = this;
    self.toggleSpinner();
    UserToolUtils.putUpdate({
      id: self.props.usership.id,
      params: self.getAuthorshipParams(),
      endpointUrl: self.props.endpointUrl,
      success: function(res) {
        self.setState({article_affiliations: self.cloneAffiliations(res.article_affiliations), errors: null}, function() {
          self.updateUsership(res)
          self.props.collapseRow(self.props.usership.id)
        })
      },
      error: function(jqXHR) {
        self.setState({errors: JSON.parse(jqXHR.responseText)});
      },
      always: self.toggleSpinner
    });
    
  },

  updateCCed: function() {
    const self = this;
    self.setState({
      channelManagementCced: !self.state.channelManagementCced
    }, function() {
      UserToolUtils.putUpdate({
        id: self.props.usership.id,
        params: {cced: self.state.channelManagementCced},
        endpointUrl: self.props.endpointUrl,
        error: function(res) {
          Cureus.Flash.alert('There was an issue, please try again.')
        },
      });
    });
  },

  handleAuthorshipCancel: function() {
    var self = this;
    self.setState({
      user: _.clone(self.props.usership.user),
      article_affiliations: self.cloneAffiliations(this.props.usership.article_affiliations),
      affiliationChanged: false,
      nameChanged: false,
      errors: self.cloneErrors(this.props.usership.affiliation_errors)
    }, self.props.handleAuthorshipCancel(self.props.usership.id))
  },

  handleAffiliationAdd: function() {
    var article_affiliations = this.state.article_affiliations;
    var count = article_affiliations.length
    var newAffiliation = {
      id: 'new_affiliation_' + this.props.usership.id + '_' + (count + 1),
      institutionName: 'Not Selected',
      departmentName: 'Not Selected',
      authorshipId: this.props.usership.id,
      country: null,
      city: null,
      isPrimary: false,
      invalidInstitutionName: false
    }
    article_affiliations.push(newAffiliation);
    this.setState({article_affiliations: article_affiliations, affiliationChanged: true})
  },

  handleAffiliationRemove: function(evt) {
    var idx = parseInt(evt.target.dataset.idx);
    var article_affiliations = this.state.article_affiliations;
    article_affiliations.splice(idx, 1)
    this.setState({article_affiliations: article_affiliations, affiliationChanged: true})
  },

  updateUsership: function(newUsership) {
    let self = this;
    let newUser = _.clone(newUsership.user)
    let newArticleAffiliations = this.cloneAffiliations(newUsership.article_affiliations);
    this.setState({user: newUser, article_affiliations: newArticleAffiliations}, self.props.updateUsership(self.props.idx, newUsership))
  },

  getAuthorshipParams: function() {
    var params = this.props.defaultParams;
    params.authorship_form = true;
    var user = this.state.user
    params.user = {
      first_name: user.first_name,
      middle_initial: user.middle_initial,
      last_name: user.last_name,
      suffix: user.suffix
    }
    params.article_affiliations = this.state.article_affiliations
    params.canAdmin = this.props.canAdmin || this.props.isEditor

    return params;
  },

  dragHandle: function() {
    if (this.props.isAuthorships) {
      return(
        <td className='drag-handle'>
          <table className="dots">
            <tbody>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
            </tbody>
          </table>
        </td>
      )
    }
  },

  actions: function() {
    if (this.props.isChannel) {
      if (this.props.current_user.id === this.props.usership.user.id) {
        return (
          <td></td>
        );
      } else if (this.props.current_user.id !== this.props.usership.user.id){
        return (
          <td className="centered">
          <a className='button tiny edit' style={{marginRight: '12px'}} target='_blank' href={"mailto:" + this.props.usership.user.email}>
            <i className='fa fa-envelope'></i>
          </a>
          {
            (this.props.canAdmin || this.props.isEditor || this.props.usership.removable) &&
            (<a className='button tiny secondary trash' href='#' onClick={ this.handleRemove }>
              <i className='fas fa-trash-alt'></i>
            </a>)
          }
          </td>
        )
      }
    } else {
      return (
        <td className="table-actions" style={{textAlign: "right"}}>
          <a className='button tiny secondary edit' href="#" onClick={ this.handleEdit }>
            <i className='fa fa-pencil-alt'></i>
          </a>
          {
            (this.props.canAdmin || this.props.isEditor || this.props.usership.removable) &&
            (<a className='button tiny secondary trash' href='#' onClick={ this.handleRemove }>
              <i className='far fa-trash-alt'></i>
            </a>)
          }
        </td>
      )
    }
  },

  renderInvalidMessages: function(aff) {
    var text;
    if(!aff.validInstitution && aff.invalidInstitutionName) {
      text = "Invalid Institution Name"
    }
    else if (!aff.institutionName || aff.institutionName == 'Not Selected') {
      text = "Missing Institution"
    }
    else if (!aff.departmentName || aff.departmentName == 'Not Selected') {
      text = "Missing Department"
    }
    else if (!aff.city || aff.city == '' || !aff.country) {
      text = "Missing Location"
    }
    else if (aff.invalidDepartmentName && !aff.validDepartment) {
      text = "Invalid Department Name"
    }
    if (text) {
      return (
        <div className="error right" style={{color: 'red', width: '20%'}}>{text}</div>
      )
    }
  },

  authorshipSubmitDisabled: function() {
    if (this.state.spinner) {
      return "disabled";
    }
  },

  authorshipActions: function() {
    if (this.props.editing) { 
      let disabled = this.authorshipSubmitDisabled();
      return (
        <td className="affiliation-action-wrap">
          <a className="button secondary" onClick={this.handleAuthorshipCancel}>Cancel</a>
          <a className="button success" onClick={this.handleAuthorshipSubmit} disabled={ disabled }>Update</a>
        </td>
      ) 
    }
    if ((this.props.submitterId !== this.props.usership.user.id) && (this.props.canAdmin || this.props.isEditor || (!this.props.pastEditorCheck && this.props.usership.removable))) {
      return (
        <td className="action-button-wrap">
          <div className="cureus-split-dropdown-wrap action-button edit-button">
            <a href="#" onClick={ this.handleEdit } className="button small secondary split no-margin" data-cureus-split-dropdown={"authorship-actions" + this.props.usership.id} >
              <div className="edit-text">Edit</div>
              <span />
            </a>
            <ul id={"authorship-actions" + this.props.usership.id} className="cureus-split-dropdown" style={{minwidth: '130px'}}>
              <li>
                <a className="dropdown-link" onClick={(e) => this.openEmailModal(e)}>Change Email</a>
              </li>
              <li>
                <a className="dropdown-link" onClick={this.handleRemove}>Delete</a>
              </li>
            </ul>
          </div>
        </td>
      )
    }
    else if (this.props.authorshipsRemovable && this.props.usership.removable) { 
      return (
        <td className="action-button-wrap">
          <div className="cureus-split-dropdown-wrap action-button edit-button">
            <a href="#" onClick={ this.handleEdit } className="button small secondary split no-margin" data-cureus-split-dropdown={"authorship-actions" + this.props.usership.id} >
              <div className="edit-text">Edit</div>
              <span />
            </a>
            <ul id={"authorship-actions" + this.props.usership.id} className="cureus-split-dropdown" style={{minwidth: '130px'}}>
              <li>
                <a className="dropdown-link" onClick={this.handleRemove}>Delete</a>
              </li>
            </ul>
          </div>
        </td>
      )
    }
    else {
      return (
        <td className="action-button-wrap">
          <a className="button small secondary edit-button no-margin" onClick={ this.handleEdit }>Edit</a>
        </td>
      )
    }
  },

  handleCorrespondent: function() {
    var self = this;
    self.props.handleLoading()
    $.ajax({
      type: 'put',
      url: self.props.usership.corresponding_author_path,
      data: Object.assign({}, self.props.defaultParams),
      dataType: 'json',
      success: function(res){
        self.props.updateUserships(res.userships)
      },
      error: function(res) {
        self.props.updateUserships(self.props.userships)
        Cureus.Flash.alert('There was an issue, please try again.')
      }
    })
  },

  openCorrespondentModal: function() {
    Modal.open({
      children: 
        <CorrespondentModal
          handleCorrespondent={this.handleCorrespondent}
        />
    })
  },

  correspondentCheck: function() {
    if (this.props.isAuthorships && this.props.usership.correspondent) {
      return (
        <td>
          <input className="table-correspondent" type='radio' value='' checked={true} onClick={this.handleCorrespondent}/>
        </td>
      )
    } else {
      return (
        <td>
          <input className="table-correspondent" type='radio' value='' checked={false} onChange={this.openCorrespondentModal} onClick={this.openCorrespondentModal}/>
        </td>
      )
    }
  },

  openEmailModal: function(e) {
    if (e.target.type == "radio" || e.target.innerText == "Remind"){
      return;
    }
    var authorship = this.props.usership
    Modal.open({
      children: <ChangeEmailModal
        idx={ this.props.idx }
        authorship={authorship}
        affiliationNameQueryUrl={ this.props.affiliationNameQueryUrl }
        deptAutocompletePath={ this.props.deptAutocompletePath }
        countryOptions={ this.props.countryOptions }
        specialtyOptions={ this.props.specialtyOptions }
        updateUsership={this.updateUsership}
        defaultParams={{authorable_type: 'Article', authorable_id: this.props.articleId}}
        endpointUrl={this.props.endpointUrl}
      />
    });
  },

  renderName: function() {
    const isPending = this.props.usership.status === "pending";
    if (this.props.isChannel && (this.props.canAdmin || this.props.isEditor)) {
      return (
        <td>
          <a href={this.props.usership.user.channel_admin_user_path } target = "_blank" >
            { this.props.usership.user.full_name }
          </a>
          {isPending && <img src="https://public.cureus.com/stop_watch.png" className="stop-watch" />}
        </td>
      )
    } else {
      return(
        <td>{ this.props.usership.user.full_name }</td>
      )
    }
  },

  authorDetails: function() {
    if (this.props.editing) {
      return (
        <td className="author-details editing">
          <AuthorForm
            id={ this.props.usership.id }
            user={ this.state.user }
            handleNameChange={ this.handleNameChange }
            handleAffiliationChange={ this.handleAffiliationChange }
            handleCountryChange={ this.handleCountryChange }
            handleAffiliationAdd={ this.handleAffiliationAdd }
            handleAffiliationRemove={ this.handleAffiliationRemove }
            countryOptions={ this.props.countryOptions }
            affiliations={ this.state.article_affiliations }
            affiliationNameQueryUrl={ this.props.affiliationNameQueryUrl }
            deptAutocompletePath={ this.props.deptAutocompletePath }
            errors={ this.state.errors }
            spinner={ this.state.spinner }
            toggleSpinner={ this.toggleSpinner }
            canAdmin={ this.props.canAdmin || this.props.isEditor }
          />
        </td>
      )
    } else {
      return (
        <td className="author-details">
          <span className="bolded">{this.props.usership.user.full_name}</span>
          &nbsp;
          &nbsp;
          {this.renderCorrespondent()}
          {this.renderAffiliations()}
          <div style={{width:"80%"}}>
            <a href={ "mailto:" + this.props.usership.user.email }>{this.props.usership.user.email}</a>
          </div>
      </td>
      )
    }
  },

  renderAffiliations: function() {
    var self = this;

    return self.state.article_affiliations.map(function(aff) { 
      return (
        <div className="affiliation-wrap">
          <div className="affiliation left">
            <span style={(aff.validInstitution || !aff.invalidInstitutionName) && !aff.validDepartment && aff.invalidDepartmentName ? {color: 'red'} : {}}>{aff.departmentName},&nbsp;</span>
            <span style={!aff.validInstitution && aff.invalidInstitutionName ? {color: 'red'} : {}}>{aff.institutionName},&nbsp;</span>
            <span>{aff.city},&nbsp;</span>
            <span>{aff.country}</span>
          </div>
          {self.renderInvalidMessages(aff)}
        </div>
      )
    })
  },

  renderCorrespondent: function() {
    if(this.props.usership.correspondent) {
      return (
        <span><i className='far fa-envelope'/>&nbsp;<a href={this.props.usership.user.path} target="_blank">Corresponding {this.props.isCollection ? 'Editor' : 'Author'}</a> </span>
      )
    }
  },

  renderRow: function() {
    if (this.props.isAuthorships) {
      return (
        <tr>
          {this.dragHandle()}
          <td className="bolded centered">{ this.props.usership.position_with_suffix }</td>
          {this.correspondentCheck()}
          {this.authorDetails()}
          {this.authorshipActions()}
        </tr>
      )
    } else if (this.props.isChannel) {
      return (
        <tr className="table-channel-row">
          {this.dragHandle()}
          {this.renderName()}
          <td><a href={ "mailto:" + this.props.usership.user.email }>{this.props.usership.user.email}</a></td>
          <td>{ this.props.usership.user.affiliation_name_full }</td>
          <td className="centered"><input checked={ this.state.channelManagementCced ? 'checked' : '' } type="checkbox" onChange={ () => this.updateCCed() } /></td>
          {this.actions()}
        </tr>
      )
    } else {
      return (
        <tr>
          {this.dragHandle()}
          {this.renderName()}
          <td><a href={ "mailto:" + this.props.usership.user.email }>{this.props.usership.user.email}</a></td>
          <td>{ this.props.usership.user.affiliation_name }</td>
          {this.actions()}
        </tr>
      )
    }
  },

  render: function() {
    return (
      this.renderRow()
    )
  }
});

export default Row;