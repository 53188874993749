import React from 'react';

const HumanAndAnimalSubjects = () => {
  return (
    <React.Fragment>
      <h1>Human and Animal Subjects</h1>
      <p>
        Where applicable, Cureus requires that authors provide information detailing the relevant ethical standards which were met when conducting research involving the study of human and/or animal participants or tissue.
      </p>
      <p>
        Authors are required to provide such information whenever research is conducted on humans or human tissue; on animals or animal tissue; when conducting field studies; or in any instance requiring the approval of an Institutional Review Board (IRB). In cases that required IRB approval, the IRB that granted the approval should be named in the article.
      </p>
      <p>This information is included in a disclosure statement attached to the final published article.</p>

      <h2>Human Subjects Research</h2>
      <p>
        For studies involving human subjects, appropriate approval must have been obtained from the relevant institutional review board or ethics committee. Before conducting the research, approval must have been obtained from an institutional review board (IRB) or ethics committee. The author must also include this information detailing their approval as part of their Materials &amp; Methods section (including the name of the granting organization, the approval reference numbers and, optionally, the statement, approval letter and/or application approval).
      </p>
      <p>
        Authors must declare that informed consent for treatment and open access publication was either obtained or waived. Where possible, a copy of the consent form should be provided as supplemental information. If consent was verbal instead of written, then an explanation should be provided (in the Materials and Methods section) and verbal consent must have been approved by the IRB which gave permission for the study.
      </p>
      <p>
        Patient privacy and anonymity must be protected. Authors must confirm that no identifying information (such as names, images and identifying data) is included in the article. Exceptions may be made only if the individuals in question have provided explicit approval. For more information about patient privacy, anonymity and informed consent, please refer to the <a href='http://www.icmje.org/recommendations' target='_blank' rel='noreferrer'>ICMJE Privacy and Confidentiality guidelines</a>.
      </p>
      
      <h2>Animal Research</h2>
      <p>
        For studies involving regulated animals (which includes all live vertebrates and/or higher invertebrates), appropriate approval must have been obtained according to either international or local laws and regulations. Before conducting the research, approval must have been obtained from an institutional review board (IRB) or ethics committee. Authors must also include this information detailing their approval as part of their Materials &amp; Methods section (including the name of the granting organization and the protocol number). It should be noted that non-human primates research is subject to specific guidelines from the Weatherall (2006) report <a href='http://www.easac.eu/fileadmin/PDF_s/reports_statements/Use.pdf' target='_blank' rel='noreferrer'>(The Use of Non-Human Primates in Research)</a>.
      </p>
      <p>
        For research conducted on non-regulated animals, the author must explain why ethical approval was not required. The handling of experimental animals should be held to the highest standards kept by the author’s institution.
      </p>
      <p>
        Cureus strongly encourages all authors to comply with the <a href='http://www.nc3rs.org.uk/page.asp?id=1357' target='_blank' rel='noreferrer'>'Animal Research: Reporting In Vivo Experiments' (ARRIVE) guidelines</a>, developed by <a href='http://www.nc3rs.org.uk/' target='_blank' rel='noreferrer'>NC3Rs</a>. Articles should be specific in descriptions of the organism(s) used in the study. When known, these descriptions should indicate strain names.
      </p>
    </React.Fragment>
  )
}

export default HumanAndAnimalSubjects;