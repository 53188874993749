import React from 'react';

const AuthorRoles = () => {
  return (
    <React.Fragment>
      <h1>Author Roles, etc.</h1>

      <p>Authors cannot be added after submission or publication. In-progress article submissions can be withdrawn at the request of the author in order to resubmit with additional authors, but the purchase of Preferred Editing will be required upon resubmission. If Preferred Editing was purchased for the initial submission, no refund will be provided and an additional purchase will be required upon resubmission.</p>
      
      <h2 className='submitting-author'>Submitting Author</h2>
      <p>The submitting author assumes all responsibility throughout the submission, peer review and publication process. Only the submitting author can edit the article, author names, contributions, and affiliations. If you are submitting on behalf of an author you must sign in with the author’s account before continuing. All Cureus email notifications sent during the submission process will be sent to the submitting author. For these reasons, we strongly recommend that authors submit their own work.</p>
      
      <h2 className='corresponding-author'>Corresponding Author</h2>
      <p>The submitting author may designate a co-author to serve as corresponding author after the article is published. Please note that the corresponding author does not have edit access.</p>
      
      <h2 className='shared-first-author'>Shared First Authorship</h2>
      <p>Cureus does not support co-first authors. If you wish to recognize two first authors, please do so by adding a statement to the Acknowledgments section.</p>

      <h2>All Submissions Must Include a Senior Author</h2>
      <p>Cureus considers authors with the following degrees to be senior authors: MD or DO (completed residency training), PhD, NP, PA, RN, DDS/DMD. Submissions authored by medical students must contain an MD or DO senior author. Similarly, submissions authored by dental students must contain a DDS/DMD senior author. Regional equivalent degrees will also be considered.</p>

      <h2>AI Chatbots, also Known as Large Language Models</h2>
      <p>No LLM tool, such as OpenAI's ChatGPT, will be accepted as a credited author on an article. That is because any attribution of authorship carries with it accountability for the work, and AI tools cannot take such responsibility. Authors using LLM tools should document this use in the Materials and Methods or Acknowledgements sections. Articles written entirely by an LLM tool will not be accepted.</p>
    </React.Fragment>
  )
}

export default AuthorRoles;