import React from 'react';


const Footer = ({text, includeDefault}) => {
  return (
    <div className='row page-footer'>
      <div className='large-12 columns'>
        <div className='footer-text-container'>
          <h3>Join Cureus</h3>
          {includeDefault && 
          (
            <p>
            Sign up for Cureus today and help revolutionize medical research through <a href='/about_publishing' target='_blank'>quick and affordable publishing</a>, <a href='/cureus_editing_services' target='_blank'>expert editing</a>, robust peer review processes, and global exposure.
            </p>
          )}
          
          <div>{text}</div>
        </div>
        <div className='footer-btn-container'>
          <div className='btn-wrapper'>
            <div>
              <a href='/about' className='button primary btn-text'>Learn More about Cureus</a>
            </div>
            <div>
              <a href='/registrations/sign_up' className='button primary btn-text'>Join Now</a>
            </div>
            <div>
              <a href='/contact' className='button primary btn-text'>Contact Us</a>
            </div>   
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;