import React from 'react';

const List = ({ isOpen = true, items, onSelect }) => {
  const isActiveIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'>
      <path
        d='M16.6668 5L7.50016 14.1667L3.3335 10'
        stroke='#0096B7'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'/>
    </svg>
  )

  const handleSelect = (onItemClick) => {
    if (onSelect) {
      onItemClick();
      onSelect();
    }
    onItemClick();
  }

  const renderList = () => {
    return (
      items.map(({ onItemClick, content, className, showIcon }) => {
        return (
          <li
            role="option"
            onClick={() => handleSelect(onItemClick)}
            key={content}
            className={className}
          >
              {content}
              {showIcon && isActiveIcon}
          </li>
        )
      })
    )
  }

  return (
    <div className="drop-down-menu">
      <ul>
        {isOpen && renderList()}
      </ul>
    </div>
  )
}

export default List;