import React, { forwardRef, memo } from 'react';
import Dropdown from '../Shared/Dropdown';
import ArticleDateSelector from '../NewArticleIndex/ArticleDateSelector';

const CollectionFilters = ({
  activeOrder,
  activeFilter,
  updateFilter,
  dateData,
  handleDateRangeSubmit,
  order,
}) => {
  const { fromDate, toDate, dateRanges, isActive } = dateData;

  const filterArrowIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        d='M6 9L12 15L18 9'
        stroke='#73797E'
        strokeWidth='1.5'
        strokeLinecap='square'/>
    </svg>
  );

  const RawButton = memo(({ forwardedRef, isLoading, disabled, ...props }) => {
    return (
      <button {...props} ref={forwardedRef}>
        {props.children}
      </button>
    );
  });

  const Button = forwardRef((props, ref) => (
    <RawButton {...props} forwardedRef={ref}/>
  ));

  const checkActiveCollectionType = (currentCollectionType) => {
    return ['CallCollection', 'CureusUCollection', 'CustomCollection', 'GuestEditorCollection'].includes(currentCollectionType);
  }

  const dropDownItems = [
    {
      content: "Call for articles",
      onItemClick: () =>  updateFilter('collectionType', 'CallCollection'),
      showIcon: activeFilter === 'CallCollection',
      className: `call-option${activeFilter === 'CallCollection' ? '--active' : ''}`
    },
    {
      content: "Cureus U",
      onItemClick: () =>  updateFilter('collectionType', 'CureusUCollection'),
      showIcon: activeFilter === 'CureusUCollection',
      className: `cureus-u-option${activeFilter === 'CureusUCollection' ? '--active' : ''}`
    },
    {
      content: "Custom collection",
      onItemClick: () =>  updateFilter('collectionType', 'CustomCollection'),
      showIcon: activeFilter === 'CustomCollection',
      className: `custom-option${activeFilter === 'CustomCollection' ? '--active' : ''}`
    },
    {
      content: "Guest editor collection",
      onItemClick: () =>  updateFilter('collectionType', 'GuestEditorCollection'),
      showIcon: activeFilter === 'GuestEditorCollection',
      className: `guest-editor-option${activeFilter === 'GuestEditorCollection' ? '--active' : ''}`
    },
  ];

  return (
    <div className="filters-container">
      <div className="date-filters-container">
        <Dropdown
          toggle={
            <Button className={`show-for-medium-up filter-button collection-type ${checkActiveCollectionType(activeFilter) ? '--active-filter' : ''}`}>
              Collection type {filterArrowIcon}
            </Button>
          }
          items={dropDownItems}
        />

        <Button
          className={`show-for-medium-up filter-button  newest ${activeOrder === 'newest' ? `--active-filter ${order.dir}` : ''}`}
          onClick={() => updateFilter('newest')}
        >
          Newest {filterArrowIcon}
        </Button>

        <Button
          className={`show-for-medium-up filter-button most-viewed ${activeOrder === 'mostViewed' ? `--active-filter ${order.dir}` : ''}`}
          onClick={() => updateFilter('mostViewed')}
        >
          Most viewed {filterArrowIcon}
        </Button>

        <Button
          className={`show-for-medium-up filter-button most-articles ${activeOrder === 'mostArticles' ? `--active-filter ${order.dir}` : ''}`}
          onClick={() => updateFilter('mostArticles')}
        >
          Most articles {filterArrowIcon}
        </Button>
      </div>

      <div className="pagination-filters-container">
        <div className="per-page-filter-container">
          <ArticleDateSelector
            fromDate={fromDate}
            toDate={toDate}
            handleDateRangeSubmit={handleDateRangeSubmit}
            selectedRange={dateRanges}
            datePickerActive={isActive}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(CollectionFilters);