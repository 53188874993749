import React, { useEffect} from 'react';
import { Helmet } from 'react-helmet';

const Introduction = ({setFooterText}) => {
  useEffect(() => {
    setFooterText(<p>
      Sign up for Cureus today and help revolutionize medical research by providing substantial critical feedback through thorough{' '}
      <a href="/reviewer_guide" target="_blank">
      peer review
      </a>
      .
    </p>);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Peer Review Process | All About Cureus Peer Review | Cureus</title>
        <meta name="description" content="Explore our peer review process. Join Cureus as a peer reviewer to provide substantial critical feedback and ensure quality in scientific publications." />
      </Helmet>
      <h1>Introduction</h1>
      <h2>What to Include in Your Review</h2>
      <p>Article credibility is determined by favorable commentary from trusted peer reviewers that includes <strong>substantial critical feedback</strong> confirming the absence of fatal scientific and methodological flaws.
      </p> 
      <p>
      <strong>Do not include comments on spelling, grammar, punctuation, or formatting — this is not part of the peer review process!</strong> Positive reviews lacking in substantial constructive feedback will be closely scrutinized by our editors and may be rejected</p>
      <h2>The Cureus Peer Review System</h2>
      <p>All submitted articles must undergo a <a href='/author_guide#!/policies-and-procedures/single-blinded-peer-review'>single-blind pre-publication peer review process</a> in which reviewers know the identity of the authors, but authors do not know the identity of the reviewers.</p>
      <p>In Cureus’ peer review process, reviewers are made anonymous through an automated system and are assigned a Greek letter for identification purposes (e.g. Reviewer Alpha) upon acceptance of the review invitation.</p>
      <p>When <a href='/author_guide#!/author-instructions/submitting-an-article'>submitting an article</a>, authors are required to suggest a minimum of five reviewers. In addition to these author-suggested reviewers, a minimum of six peer reviewers are automatically invited from the Cureus internal panel. </p>
      <p>This panel consists of relevant specialists who have volunteered for the panel or previously published as an author in Cureus. This ensures that a minimum of 11 peer reviewers are invited to review each article.</p>
      <p>Medical students may not be invited to peer review. Before forwarding an invitation, each author-submitted reviewer is carefully vetted by <a href='/associate_editors'>our editorial staff</a> with a specific focus on relevant scientific expertise.</p>
      <p>Once the article has entered the Cureus peer review system, three additional reviewers are automatically invited by Cureus every two days to ensure that the article completes peer review in a timely fashion.</p>
      <p>Two completed reviews from Cureus-invited reviewers are required to complete the peer review process.</p>
      <h2>Ending the Peer Review Period</h2>
      <p>When the requirements for Cureus peer review have been met, authors can choose to end the peer review period for all reviewers. If chosen, this option will expire all outstanding and in-progress reviews, except for any reviews that were started within the prior 48 hours.</p>
      <p>
      These reviewers are given a maximum of 24 hours to submit their review. However, the review period may end sooner if the author revises and submits for publication approval.
      </p>
      <p>Authors waiting on a Cureus-invited review (and have received at least one review from an author-invited reviewer) can also choose to end the peer review process for the remaining author-invited reviewers.</p>
      <p>Any reviewers who started their review in the prior 48 hours will be notified that they have a maximum of 24 hours remaining to submit their review.</p>
      <h2>Reviewer Keywords</h2>
      <p>Users who have published an article in <a href='/'>Cureus</a> or volunteered for our peer review panel are prompted to select up to 20 keywords to ensure they are matched with articles in their areas of interest and expertise. </p>
      <p>
      When you start typing in the keyword field, a list of suggested keywords will be displayed so you can make your selection. (New keywords cannot be added at this time, but don’t worry — our list is quite extensive.) 
      </p>
      <p>
      As part of the peer review process, peer reviewers will then be matched with relevant articles based on their keywords.
      </p>
    </React.Fragment>
  )
}

export default Introduction;