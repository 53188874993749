import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Search
import SearchResults from './SearchResults';
// Overview
import Introduction from './AuthorPages/Introduction';
import FreePublication from './AuthorPages/FreePublication';
import AcceptedArticleTypes from './AuthorPages/AcceptedArticleTypes';
import UnacceptedArticleTypes from './AuthorPages/UnacceptedArticleTypes';
import StepByStepOverview from './AuthorPages/StepByStepOverview';

// Author Instructions
import AuthorRoles from './AuthorPages/AuthorRoles';
import SubmittingAnArticle from './AuthorPages/SubmittingAnArticle';
import ManagingPeerReview from './AuthorPages/ManagingPeerReview';
import References from './AuthorPages/References';
import SeoTips from './AuthorPages/SeoTips';
import PromotingYourResearch from './AuthorPages/PromotingYourResearch';

// Policies and Procedures
import SingleBlindedPeerReview from './AuthorPages/SingleBlindedPeerReview';
import IcmjeRequirements from './AuthorPages/IcmjeRequirements';
import PlagiarismMisconductAndEthics from './AuthorPages/PlagiarismMisconductAndEthics';
import ConflictsOfInterest from './AuthorPages/ConflictsOfInterest';
import HumanAndAnimalSubjects from './AuthorPages/HumanAndAnimalSubjects';
import RaceAndEthnicity from './AuthorPages/RaceAndEthnicity';
import Artificialintelligence from './AuthorPages/ArtificialIntelligence';
import DataCitationAndTransparency from './AuthorPages/DataCitationAndTransparency';
import Preregistration from './AuthorPages/Preregistration';
import ReplicationStudies from './AuthorPages/ReplicationStudies';
import DisciplineSpecificStandards from './AuthorPages/DisciplineSpecificStandards';
import Confidentiality from './AuthorPages/Confidentiality';
import CommentingPolicies from './AuthorPages/CommentingPolicies';
import CopyrightAndLicensing from './AuthorPages/CopyrightAndLicensing';
import RetractionPolicy from './AuthorPages/RetractionPolicy';
import BusinessModel from './AuthorPages/BusinessModel';

const AuthorGuide = ({ query, urls, setFooterText, setIncludeDefault }) => {

  return (
    <Switch>
      <Route path="/overview/introduction">
        <Introduction/>
      </Route>
      <Route path="/overview/free-publication">
        <FreePublication setFooterText={setFooterText}/>
      </Route>
      <Route path="/overview/accepted-article-types">
        <AcceptedArticleTypes/>
      </Route>
      <Route path="/overview/unaccepted-article-types">
        <UnacceptedArticleTypes/>
      </Route>
      <Route path="/overview/step-by-step-overview">
        <StepByStepOverview setFooterText={setFooterText}/>
      </Route>
      <Route path="/overview">
        <Redirect to="/overview/introduction"/>
      </Route>
      <Route path="/author-instructions/author-roles">
        <AuthorRoles/>
      </Route>
      <Route path="/author-instructions/submitting-an-article">
        <SubmittingAnArticle setFooterText={setFooterText}/>
      </Route>
      <Route path="/author-instructions/managing-peer-review">
        <ManagingPeerReview/>
      </Route>
      <Route path="/author-instructions/references">
        <References/>
      </Route>
      <Route path="/author-instructions/seo-tips">
        <SeoTips/>
      </Route>
      <Route path="/author-instructions/promoting-your-research">
        <PromotingYourResearch/>
      </Route>
      <Route path="/author-instructions">
        <Redirect to="/author-instructions/author-roles"/>
      </Route>
      <Route path="/policies-and-procedures/single-blinded-peer-review">
        <SingleBlindedPeerReview setIncludeDefault={setIncludeDefault} setFooterText={setFooterText}/>
      </Route>
      <Route path="/policies-and-procedures/icmje-requirements">
        <IcmjeRequirements/>
      </Route>
      <Route path="/policies-and-procedures/plagiarism-misconduct-and-ethics">
        <PlagiarismMisconductAndEthics/>
      </Route>
      <Route path="/policies-and-procedures/conflicts-of-interest">
        <ConflictsOfInterest/>
      </Route>
      <Route path="/policies-and-procedures/human-and-animal-subjects">
        <HumanAndAnimalSubjects/>
      </Route>
      <Route path="/policies-and-procedures/race-and-ethnicity">
        <RaceAndEthnicity/>
      </Route>
      <Route path="/policies-and-procedures/artificial-intelligence">
        <Artificialintelligence/>
      </Route>
      <Route path="/policies-and-procedures/data-citation-and-transparency">
        <DataCitationAndTransparency/>
      </Route>
      <Route path="/policies-and-procedures/preregistration">
        <Preregistration/>
      </Route>
      <Route path="/policies-and-procedures/replication-studies">
        <ReplicationStudies/>
      </Route>
      <Route path="/policies-and-procedures/discipline-specific-standards">
        <DisciplineSpecificStandards/>
      </Route>
      <Route path="/policies-and-procedures/confidentiality">
        <Confidentiality/>
      </Route>
      <Route path="/policies-and-procedures/commenting-policies">
        <CommentingPolicies/>
      </Route>
      <Route path="/policies-and-procedures/copyright-and-licensing">
        <CopyrightAndLicensing/>
      </Route>
      <Route path="/policies-and-procedures/retraction-policy">
        <RetractionPolicy/>
      </Route>
      <Route path="/policies-and-procedures/business-model">
        <BusinessModel/>
      </Route>
      <Route path="/policies-and-procedures">
        <Redirect to="/policies-and-procedures/single-blinded-peer-review"/>
      </Route>
      <Route path="/search">
        <SearchResults query={ query } guide={ 'author_guide' }/>
      </Route>
      <Redirect to="/overview/introduction"/>
    </Switch>
  )

}

export default AuthorGuide;